const test = {
  // Backend
  imStrongURL: 'https://touroperating.test.perfectstay.io',
  touropApiURL: 'https://tourop-api.test.perfectstay.io',
  marketingApiURL: 'https://marketing-api.test.perfectstay.io',
  np6ApiURL: 'https://np6-api.test.perfectstay.io',
  promoCodesApiURL: 'https://promocodes.test.perfectstay.io',
  authenticatorApiURL: 'https://authenticator.test.perfectstay.io',
  sanityGenerateTokenURL: 'https://sanity-login.test.perfectstay.io/generateToken',
  creditApiURL: 'https://credit-api.marketing.test.perfectstay.io',

  // Sanity
  sanityStudioURL: 'https://sanity-marketing.test.perfectstay.io',
  sanityProjectID: 'c28t611n',
  sanityDataset: 'test',

  // Cognito
  cognitoUserPoolID: 'eu-west-1_PdBrhTds9',
  cognitoUserPoolWebClientID: '12ffsda03v3q6sq13e3o14bc5o',

  // AWS
  awsRegion: 'eu-west-1',

  // OAuth
  oAuthDomain: 'perfectdev.auth.eu-west-1.amazoncognito.com',
  oAuthRedirectSignIn: 'https://marketing.test.perfectstay.io/login',
  oAuthRedirectSignOut: 'https://marketing.test.perfectstay.io/logout',

  airtableRankingURL: 'https://airtable.com/app4t3PXiU813mwsi/tblhiJViL6duQrFUg?blocks=hide',

  realmAppId: 'marketing-test-hliof',
  realmApiKey: 'yEdAsqlvlqf9GTtSZwVKIcXSi1YJo35qUx943GPO2D1HG61FC39Z60VMG0P0AOkZ',

  accessKeyId: 'AKIAQZHHOHWJW3GJB26N',
  secretAccessKey: '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',
}

export default test
